<template>
  <div class="is-flex is-flex-direction-column has-gap-4">
    <div>
      <h3 class="title">
        {{ $tf("contractForm.base.title|Új szerződés felvétele") }}
      </h3>
      <div class="columns mt-5">
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractName|Szerződött neve')"
            label-position="on-border"
          >
            <b-autocomplete
              v-model="contractorName"
              :data="filteredEmployees"
              clearable
              field="name"
              icon="search"
              :placeholder="
                $tf('contractForm.manager.placeholder|pl. Elektrom Ágnes')
              "
              rounded
              @select="(option) => (contractEdit.userId = option.id)"
              class="has-blue-icon"
            >
              <template v-slot:empty>{{
                $tf("contractForm.contractor.empty|Nincs találat")
              }}</template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="
              $tf(
                'contractForm.base.contractCompetence|Szerződött kompetenciája'
              )
            "
            label-position="on-border"
          >
            <b-select
              :disabled="!contractEdit.userId"
              v-model="contractEdit.contractCompetence"
              :placeholder="
                $tf(
                  'contractForm.base.contractCompetence.placeholder|Válassz kompetenciát...'
                )
              "
              expanded
            >
              <option :value="null"></option>
              <option
                v-for="competency in filteredCompetencies"
                :key="competency.id"
                :value="competency.id"
              >
                {{ competency.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractStart|Szerződés kezdete')"
            label-position="on-border"
          >
            <vuelidated-input
              type="date"
              :validation-rule="v$.contractEdit.contractStart"
              v-model="contractEdit.contractStart"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              icon="calendar-day"
              trap-focus
            >
            </vuelidated-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.contractExpiry|Szerződés vége')"
            label-position="on-border"
          >
            <vuelidated-input
              type="date"
              :validation-rule="v$.contractEdit.contractExpiry"
              v-model="contractEdit.contractExpiry"
              :placeholder="$tf('moneyModal.choose|Válassz')"
              icon="calendar-day"
              trap-focus
            >
            </vuelidated-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <money-input
            v-model="contractEdit.hourlyFee"
            label-position="on-border"
            :label="$tf('contractForm.base.hourlyFee|Óradíj')"
            :validation-rule="v$.contractEdit.hourlyFee"
          />
        </div>
        <div class="column">
          <b-field
            :label="$tf('contractForm.base.monthlyHours|Havi óraszám')"
            label-position="on-border"
          >
            <vuelidated-input
              :validation-rule="v$.contractEdit.monthlyHours"
              v-model="contractEdit.monthlyHours"
              type="number"
              :expanded="true"
            ></vuelidated-input>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <b-button type="is-info" :expanded="true" @click="createContract"
          >Mentés</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MoneyInput from "@/components/module/input/MoneyInput.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import VuelidatedInput from "@/components/module/input/VuelidatedInput.vue";
import { correctWithTimezoneOffset, deepCopy } from "@/utils/util";
import { mapGetters } from "vuex";

export default {
  name: "OutsourceContractForm",
  components: { VuelidatedInput, MoneyInput },
  emits: ["contract-created"],
  setup: () => ({ v$: useVuelidate() }),
  props: {
    projectIdentifier: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      contractorName: "",
      contractEdit: {},
      contractName: "",
    };
  },
  validations: {
    contractEdit: {
      userId: {
        required,
      },
      contractCompetence: {
        required,
      },
      contractStart: {
        required,
      },
      contractExpiry: {
        required,
      },
      monthlyHours: {
        minValue: minValue(1),
        required,
      },
      hourlyFee: {
        minValue: minValue(1),
        required,
      },
    },
  },
  computed: {
    filteredCompetencies() {
      if (!this.contractEdit.userId) {
        return [];
      } else
        return (
          this.employees.find((e) => e.id === this.contractEdit.userId)
            ?.competencies || []
        );
    },
    filteredEmployees() {
      let leads = this.employees || [];
      return leads.filter((element) => {
        if (!this.contractorName || !element.name || !element.username)
          return false;
        return (
          element.name
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.contractorName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0 ||
          element.username
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(
              this.contractorName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            ) >= 0
        );
      });
    },
    ...mapGetters({
      issueTrackingSystem: "uiConfigStore/getIssueTrackingSystem",
    }),
  },
  methods: {
    async createContract() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const request = deepCopy(this.contractEdit);
      request.contractStart = correctWithTimezoneOffset(
        new Date(request.contractStart)
      );
      request.contractExpiry = correctWithTimezoneOffset(
        new Date(request.contractExpiry)
      );
      await this.$store.dispatch("outsource_projects/createContract", {
        identifier: this.projectIdentifier,
        contract: {
          ...request,
        },
      });
      this.$emit("contract-created");
    },
  },
};
</script>

<style scoped lang="scss"></style>
